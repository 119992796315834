.carousel {
    // display: flex;
    flex-wrap: nowrap;
    // animation: slide 10s linear infinite;
    animation-name: slide;
    animation-delay: 2s;
    animation-timing-function: linear;
    animation-duration: 40s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding-left: 40vw;
}

.carousel2 {
    padding-left: 40px;
    padding-left: calc(40vw + 100px);

}

.carouselSmallScreen {
    padding-left: 16px;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-90%);
    }
}