
.bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    transition: transform 0.3s ease-out;
    transform: translateY(60px);
  }
  
  .bar.visible {
    transform: translateY(0);
  }